@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
/* Slick */

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css');

@import url('https://rawgit.com/xdan/datetimepicker/master/jquery.datetimepicker.css');

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%}.flickity-slider{position:absolute;width:100%;height:100%}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:-webkit-grab;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:-webkit-grabbing;cursor:grabbing}.flickity-button{position:absolute;background:hsla(0,0%,100%,.75);border:none;color:#333}.flickity-button:hover{background:#fff;cursor:pointer}.flickity-button:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-button:active{opacity:.6}.flickity-button:disabled{opacity:.3;cursor:auto;pointer-events:none}.flickity-button-icon{fill:currentColor}.flickity-prev-next-button{top:50%;width:44px;height:44px;border-radius:50%;transform:translateY(-50%)}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button .flickity-button-icon{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;opacity:.25;cursor:pointer}.flickity-page-dots .dot.is-selected{opacity:1}


body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  display: block !important;
}

body:not(.visual-build,.visual-default){
  opacity: 0 !important;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container:before, .container:after{
    display: none !important;
}

.slick-prev:before,
.slick-next:before {
  color: #222;
}

/* iframe {
  width: 120% !important;
  height: 379px !important;
}
 */
@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}


/* AJUSTES */
.logo a img{
    position: relative !important;
}

.desk-icons i {
    color: #000;
}

#barraTopo{
    display: none !important;
}

.banner-full .div-principal .bannerF{
    width: 100%;
    /*position: relative !important;*/
    /*left: 0px !important;*/
    /*right: 0px !important;*/
}

.banner-full .div-principal img{
    width: 100%;
}

.shelf[data-type='static'] .container{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 20px;
	padding: 0 60px;
}

.product{
  position: relative;
}

.product a{
	display: flex;
	flex-direction: column;
	text-align: center;
}

.product-image{
	min-width: 80px;
	min-height: 80px;
	background: rgb(234 234 234);
}

.maintence-warning{
	display: none !important;
}

.product-price.old-price{
	text-decoration: line-through;
}

.product-button{
	padding: 10px;
	border: 1px solid #000;
}

.input-append, .input-prepend {
    display: flex;
    margin-bottom: 10px;
    vertical-align: middle;
    font-size: 0;
    white-space: nowrap;
}

.conteiner{
	position: relative;
	max-width: unset;
}

.listagem-item{
	position: relative;
}

.loadComponent{
  border: 16px solid #f3f3f3; /* Light grey /
  border-top: 16px solid #3498db; / Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/*** PLUGINS ***/
.btn-favorite{
	position: absolute !important;
    right: 10px;
    top: 10px;
    background-color: rgba(255,255,255,.9);
    width: 25px;
    height: 25px;
    display: flex !important;
    justify-content: center !important;
    border-radius: 50%;
    font-size: 14px;
    z-index: 50;
    text-decoration: none !important;
    align-items: center;
}

.btn-favorite:hover i{
	color: #dc3545;
}

.tag-off{
	position: absolute !important;
    left: 10px;
    top: 10px;
    background-color: #0062cc;
    width: 40px;
    height: 40px;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    border-radius: 50%;
    font-size: 12px;
    z-index: 50;
    text-decoration: none !important;
    align-items: center;
    font-weight: 600;
    color: #fff;
}

.tag-off span{
	line-height: 1;
}

.tag-off > span:nth-child(2){
	font-weight: normal !important;
}

.botao-whatsapp {
    margin-top: 6px;
    border: 1px solid;
    border-radius: 4px;
    border-color: #159a4a;
}

.botao-whatsapp a {
    display: flex;
    flex-direction: row-reverse !important;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 13px;
    line-height: 18px;
    padding: 5px;
}

.botao-whatsapp svg {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    fill: #159a4a;
}

#scrolltop-ldt {
    position: fixed;
    bottom: 86px;
    right: 22px;
    display: none;
    z-index: 999;
    background: var(--cor-principal,--cor-primaria,--color-primary);
    width: 50px;
    height: 50px;
    text-align: center;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

#scrolltop-ldt a {
    color: #fff;
    display: table;
    width: 100%;
    height: 100%;
    text-decoration: none;
}

#scrolltop-ldt a:before {
    display: table-cell;
    vertical-align: middle;
}

// div[id^="gb-widget-"] > div:last-child,
a[href^="https://getbutton.io/"]{
  display: none;
}

body.visual-build{
	opacity: 1 !important;
}

.mini-banners .div-principal{
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.top-bar{
  height: 40px;
}

.top-bar a{
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.top-bar strong {
  display: block;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.875em;
  line-height: 1;
  font-weight: 400;
}

div[data-content=".marcas"]{
	margin: 65px auto;
}

div[data-content=".marcas"] a{
	display: block;
  text-align: center;
}

@media (min-width: 768px) {
  div[data-content=".marcas"] a{
    width: 300px;
  }
}

@media(max-width: 767px){
  div[data-content=".marcas"] a{
    width: 100%;
  }
}

.shelf[data-type="static"] > .container{
	display: flex !important;
	flex-wrap: wrap;
	justify-content: start;
}
@media(max-width: 767px){
  .shelf[data-type="static"] > .container > div{
		width: 48% !important;
    margin: 0 !important;
    padding: 0 !important;
	}

  .shelf[data-type="static"] > .container{
    gap: 10px !important;
  }
}
@media(min-width: 768px){
	.shelf[data-type="static"] > .container > div{
		width: 22.7% !important;
	}
  .shelf[data-type="static"] > .container{
    /* gap: 0 !important; */
  }
}

/* POPUP */
#vb-popup div.section{
  margin: 10px;
 }
 
 #vb-popup .newsletter-cadastro{
   margin: 0;
 }
 
 #vb-popup .componente{
   border: none;
   background-color: transparent;
 }
 
 #vb-popup .componente .interno{
   padding: 0
 }
 
 #vb-popup .newsletter-cadastro input{
    height: 40px;
    border-radius: 0;
 }

 #vb-popup .newsletter-cadastro button{
    // height: 50px;
 }
 
 #vb-popup .newsletter-cadastro button{
   background-color: #000;
 }
 
 #vb-popup .newsletter-cadastro button:before{
   content: "Assinar";
   color: #fff;
   text-transform: capitalize;
   font-weight: 400;
 }
 
 @media(min-width: 992px){
   #vb-popup.lado{
     display: flex;
       align-items: center;
   }
   
   #vb-popup.lado img{
     width: 222px !important;
   }
 }

 .visual-default{
  opacity: 1;
  overflow: hidden;
}

.visual-default  .simples-tema{
  display: none;
}

.visual-default .store-default{
  padding: 20px;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 0;
  z-index: 99999999999999999;
}

 .visual-default .vb_button_primary {
   font-size: 14px !important;
   font-weight: 500;
   cursor: pointer;
   width: 185px;
   height: 45px;
   background: #D5156A;
   border-radius: 5px;
   color: #fff;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;
} 

.visual-default .vb_button_secondary {
   font-size: 14px !important;
   font-weight: 500;
   cursor: pointer;
   width: 116px;
   height: 45px;
   background: #fff !important;
   background-color: #fff !important;
   border-radius: 5px;
   color: #D5156A;
   border: none;
   display: flex;
   justify-content: center;
   border: 1px solid #D5156A;
   align-items: center;
}

.visual-default div{
  display: flex;
   justify-content: space-between;
   gap: 20px;
}

.visual-default .modal{
  display: none;
}

.visual-default #cabecalho{
  display: none !important;
}


#plugins_observacao,
.carrinho-checkout textarea[name=cliente_obs]{
	display: none;
}




/* =============== PLUGIN SOCIAL FLUTUANTE =============== */
  .float-soc{
    position: fixed;
    bottom: 22px;
    z-index: 9999999999999999999999;
    
    transition: all .2s ease-out;
  }

  .float-soc.hide{
    display: none;
  }

  .float-soc:not(.active){
    width: 50px;
  }

  .float-soc.active{
    width: 345px;
    
    animation: fadeFloatSoc .2s;
    z-index: 9999999;
  }

  .float-soc.right{
    right: 22px;
  }

  .float-soc.left{
    left: 22px;
  }

  .float-soc .icon{
    display: flex;
    cursor: pointer;
  }

  .float-soc.left .icon{
    justify-content: flex-start;
  }

  .float-soc .icon{
    justify-content: flex-end;
  }

  .float-soc .icon > div{
    width: 60px !important;
    height: 50px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 300px;
  }
  
  .float-soc.active .icon > div{
    height: 60px !important;
  }

  // .float-soc .icon svg{
  //   fill: rgb(255, 255, 255);
  //   stroke: none;
  //   width: 60px;
  //   height: 45px;
  //   border-radius: 300px;
  //   /* padding: 10px; */
  //   padding: 6px;
  //   margin: 0;
  //   padding-right: 15px;
  //   padding-top: 10px;
  // }

  .float-soc .content{
    box-shadow: 2px 2px 15px 2px rgb(0 0 0 / 17%);
    border: 1px solid #e2e2e2;
    position: relative;
    min-height: 149px;
    border-radius: 10px;
    background-color: white;
    
    display: none;
    gap: 0 20px;
    /*align-items: center;*/
    padding: 20px;
    margin-bottom: 10px;
  }
  
  .float-soc.active .content{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .float-soc .message-block-photo{
    height: 50px;
    width: 50px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    float: left;
    flex: 0 1 50px;
  }
  
  .float-soc .right{
    text-align: left;
    padding: 14px 16px;
    line-height: 20px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    word-wrap: break-word;
    flex: 0 1 198px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    min-height: 56px;
    font-size: 14px;
    
    position: relative;
  }
  
  .float-soc .right:after {
    content: '';
    position: absolute;
    top: 20px;
    left: -9px;
    border: 5px solid transparent;
    border-right: 5px solid #fff;
  }

  .float-soc .right:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -10px;
    border: 5px solid transparent;
    border-right: 5px solid #e2e2e2;
  }

  .float-soc .content .list{
    
  }

  .float-soc .content .list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
    padding: 10px 5px;
    flex: 0 1 calc(100% - 70px);
  }

  .float-soc .content .list a{
    flex: 0 1 28px;
  }

  @keyframes fadeFloatSoc {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 00;
  }

  100% {
    opacity: 1;
  }
  }
/* =============== PLUGIN SOCIAL FLUTUANTE =============== */

/* =============== SISTEMA DE AGENDAMENTO =============== */
.xdsoft_datetimepicker.xdsoft_noselect.xdsoft_.xdsoft_inline {
  width: 100%;
}

.xdsoft_datetimepicker .xdsoft_datepicker{
  width: 235px;
}


.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled, .xdsoft_datetimepicker .xdsoft_time_box >div >div.xdsoft_disabled{
  cursor: not-allowed;
}

.xdsoft_datetimepicker.xdsoft_noselect.xdsoft_.xdsoft_inline {
  width: 100%;
}

.xdsoft_datetimepicker .xdsoft_datepicker{
  width: 100%;
}


.xdsoft_timepicker.active {
display: none !important;
}

.xdsoft_time {
  background: #f5f5f5;
  border-top: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: center;
  border-collapse: collapse;
  cursor: pointer;
  border-bottom-width: 0;
  height: 25px;
  line-height: 25px;
  margin: 5px;
}

.calendario_horarios_lista {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}



.calendario_horarios_lista .xdsoft_time.active{
  background: #9a9999;
  color: #fff;  
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current{
  border: 1px solid #ddd;
  box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current.xdsoft_disabled:hover{
  border: 1px solid #ddd !important;
  box-shadow: none !important;
}
/* =============== SISTEMA DE DEPOIMENTO =============== */

.box-review {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #f1efef;
}

.info-client {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.info-client .client-img {
  width: 40px;
  height: 40px;
  border: none;
  margin-right: 7px;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
}

.info-client .client-name {
  font-size: 14px;
  font-weight: 700;
  color: #222;
}

.review-stars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: transparent;
  z-index: 100;
}

.wrap-stars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.review-stars .alr-icon-star:before {
  font-size: 15px;
}

.review-client {
  padding: 10px 0 0 0;
}

.review-client p {
  font-size: 16px;
  text-align: center;
  color: #222;
}

.review-date {
  text-align: right;
}

.review-date span {
  font-size: 12px;
  font-weight: 500;
  color: #222;
}

.assessments ul {
  margin: 0;
}

.assessments {
  margin: 30px 0 0 0;
}

.reviews .slick-prev {
  left: -15px;
  z-index: 99;
}

@media(max-width: 992px){
  .reviews .slick-prev{
    left: 0 !important;
  }
  .reviews .slick-next{
    right: 0 !important;
  }
}

.reviews .slick-slide {
  margin: 10px;
}


.reviews .assessments {
  padding: 0 !important;
}

.reviews .slick-next {
  right: -15px;
}

.reviews .slick-next:before,
.reviews .slick-prev:before {
  display: none;
}

.alr-icon-star {
  color: #faad14;
  font-size: 14px;
}

.alr-icon-star:before {
  font-family: fontAwesome;
  content: "\f005";
  box-sizing: border-box;
  font-style: normal;
}

.graphic-reviews {
margin: 40px 0;
}

.graphic-reviews header {
text-align: center;
}

.graphic-reviews header h3 {
  text-align: left;
  // color: #000;
}
/* =============== SISTEMA DE RECOMENDAÇÃO CARRINHO =============== */
.card-container,
.card-container .slick-track {
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	gap:15px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding:15px 0;
}

.card-container .slick-next:before,
.card-container .slick-prev:before {
  content: "";
  opacity: 0;
}

.card-container .over {
	text-decoration:line-through;
}

.card-container .card-product {
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	min-width: 200px;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	gap:20px;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	background:#fff;
	padding:12px;
	border-radius:8px;
	-webkit-transition:.2s;
	-o-transition:.2s;
	transition:.2s;
	cursor: pointer;
}

.card-container .card-product:hover {
	-webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;	
}

.card-container .card-product img {
	width:100%;
	max-width:180px;
	height:100%;
	-o-object-fit: cover;
	   object-fit: cover;
}

.card-container .card-product .card-info {
	text-align: center;
}

.card-container .card-product .card-info h1 {
	font-size:18px;
	line-height: 25px;
}

.card-container .card-product .card-info p {
	font-size:14px;
	margin-bottom:20px;
	color:var(--cor-secundaria);
}

.card-container .card-product .card-info .card-btn {
  font-size: 16px;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	background:#222;
	color:#fff;
	padding:10px;
	margin-bottom: 10px;
	border-radius:4px;
	text-decoration: none;
	-webkit-transition: .1s 50ms;
	-o-transition: .1s 50ms;
	transition: .1s 50ms;
}

.card-container .card-product .card-info .card-btn:hover {
	-webkit-transform: scale(1.1);
	    -ms-transform: scale(1.1);
	        transform: scale(1.1);
}


@media (max-width:950px) {
	.card-container {
		-webkit-box-pack: start;
		    -ms-flex-pack: start;
		        justify-content: flex-start;
	}
	
	.card-container .card-product {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding:10px;
		-webkit-box-flex: 0;
		    -ms-flex: 0;
		        flex: 0;
	}
	
	.card-container .card-product img {
		max-width: 120px;
	}
		
	.card-container .card-product .card-info .card-btn {
		font-size:12px;
	}
}
/* =============== SISTEMA DE PROVA SOCIAL ALERTA =============== */


.recent__sales_product {
  width: 300px;
  margin: 11px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #fff;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  color: #222;
  
}


.recent__sales_products span{
  position: relative;
}

.recent__sales_product > div {
  display: flex;
  align-items: center;
  padding: 10px;
}

.recent__sales_product .sales_image img {
  width: 150px;
  height: 90px;
  object-fit: contain;
}

.recent__sales_product .sales_image {
  margin-left: 12px;
  width: 20%;
  margin-right: 10px;
  display: flex;
  align-items: center;
}


span.client__name {
  display: block;
  margin-bottom: 17px;
  font-size: 14px;
}

span.product__name {
  font-weight: bold;
  font-size: 15px;
  color: #222;
}

.sales_close {
  cursor: pointer;
}

.sales_close i {
  position: absolute;
  top: 12px;
  right: 6px;
  font-size: 16px;
}


@media(max-width: 992px){

  /* PROVA SOCIAL */
  .recent__sales_product{
      max-width: 89%;
      padding: 4px;
      left: 0px;
  }
  
  span.client__name{
      font-size: 12px;
      margin-bottom: 9px;
  }
  
  span.product__name {
      font-size: 13px;
  }
  
  .recent__sales_product > div{
      padding: 0px;
  }
  
  .recent__sales_product .sales_image img {
      width: 45px;
      height: 45px;
  }
}
/* =============== SISTEMA DE CONTADOR NA VITRINE =============== */
.countdown {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.promotionTime {
	display: flex;
    flex-direction: row;
    gap: 20px;
}
@media(max-width: 767px){
  .promotionTime{
    gap: 10px !important;
  }
}

.promotionTime .field {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	background: #fff;
	border-radius: 3px;
}

.promotionTime .field h2 {
	margin: 0;
	padding: 0;
	color: #000;
	font-size: 28px;
}

.promotionTime .field span {
	color: #000;
}

.countdown-content {
	    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.countdown-content strong {
	display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #000;
    margin-right: 20px;
    /*margin-bottom: 20px;*/
}

.countdown h2 {
    margin: 0;
    padding: 20px 0;
    line-height: 45px;
    color: #3b3b3b;
    text-transform: none;
    font-size: 26px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}


.countdown-theme {
	padding: 20px 5px 40px !important;
  margin: 20px 0;
  display: none;
}

.countdown-theme .field h2,
.countdown-theme .field span {
	color: #fff;
}

/*.countdown-content {*/
/*	flex-direction: row;*/
/*}*/

.countdown-theme .countdown {
	margin-top: 10px;
	margin-bottom: 20px;
}

.direction-column {
	flex-direction: column;
}

.direction-line {
	flex-direction: row;
}


@media (max-width: 768px) {
	
	.countdown-theme .countdown {
		display: flex;
		flex-direction: column;
	}
	
	.promotionTime .field {
		width: 60px;
		height: 80px;
	}
	
	.promotionTime {
		justify-content: center;
	}
	
	.promotionTime .field h2 {
		font-size: 24px;
	}
	
	.promotionTime .field span {
		font-size: 12px;
	}
	
	.countdown h2 {
		text-align: center;
	}
	
	.countdown-content strong {
		margin-right: 5px;
		margin-bottom: 20px;
	}

  .countdown-theme .product-name{
    padding: 0 5px;
  }
}
/* =============== SISTEMA DE WHATSAPP CARRINHO =============== */
.finalizar-compra .acao-editar .span12 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.wpp-buy {
	display: flex;
    flex-direction: row;
    align-items: center;
	  background: #fff;
    border: 0;
    border-radius: 4px;
    text-shadow: none;
    padding: 15px 25px;
    font-weight: 700;
    font-size: 18px;
    color: #4DC247;
    letter-spacing: -.3px;
    height: 54px;
    transition: 0.2s ease-in-out;
    border: 2px solid #4DC247;
    font-size: 14px;
    
    
    /* inverter no mobile */
    
    border: none;
    justify-content: flex-end;
    padding: 0 20px;
    font-size: 16px;
    gap: 5px;
    text-decoration: none;
    margin-top: -17px;
    padding-right: 15px;
}

.wpp-buy:focus {
	color: #4DC247;
}

.wpp-buy svg {
	fill: #4DC247;
}

.wpp-buy:hover {
	opacity: .9;
	color: #4DC247;
	text-decoration: underline;
}



.wpp-buy span {
	display: flex;
	flex-direction: row;
	align-items: center;
}

@media (max-width: 992px) {
	.finalizar-compra .acao-editar .span12 {
		flex-direction: column;
	}
	
	.wpp-buy {
		justify-content: center;
	}
	
	.wrap-btns {
		display: flex;
		flex-direction: column-reverse;
	}
}
/* =============== SISTEMA DE COMPRAR NOVAMENTE =============== */
body.buyAgainPlugin .caixa-dados > h4.titulo {
  display: none;
}
body.buyAgainPlugin .caixa-dados #buyAgainDiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
body.buyAgainPlugin .caixa-dados #buyAgainDiv h4.titulo {
  display: block;
}
body.buyAgainPlugin .caixa-dados #buyAgainDiv button {
  color: #1515ad;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  outline: 0;
  letter-spacing: 0.5px;

  background: #25734f;
  color: #FFF;
  border-radius: 5px;
  padding: 9px 12px;
  font-size: 15px;
  border: none;
  text-transform: capitalize;
}
body.buyAgainPlugin .caixa-dados #buyAgainDiv button:hover {
  opacity: .7;
}

body.buyAgainPlugin .caixa-dados #buyAgainDiv .loader {
	border: 5px solid #f3f3f3;
    border-top: 5px solid #485e6c;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    -webkit-animation: spin 1.7s linear infinite;
            animation: spin 1.7s linear infinite;
    position: absolute;
    right: 260px;
    display: none;
}

body.buyAgainPlugin .caixa-dados #buyAgainDiv.loading .loader{
	display: block;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

body.buyAgainPlugin .caixa-dados #buyAgainDiv #productsUnavailable{
	display: none;
  position: absolute;
  right: 260px;
  color: #bc3b3b;
  font-size: 12px;
}

body.buyAgainPlugin .caixa-dados #buyAgainDiv.unavailable #productsUnavailable{
	display: block;
}

body.buyAgainPlugin tr .buy-product{
	display: none;
}

@media screen and (max-width: 1035px){
	body.buyAgainPlugin .caixa-dados #buyAgainDiv.unavailable{
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	}
	
	body.buyAgainPlugin .caixa-dados #buyAgainDiv.unavailable>h4{
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
	    width: 50%;
	}
	
	body.buyAgainPlugin .caixa-dados #buyAgainDiv.unavailable #productsUnavailable{
		position: static;
	    -webkit-box-ordinal-group: 1;
	        -ms-flex-order: 0;
	            order: 0;
	    width: 100%;
	    margin-bottom: 15px;
	    text-align: center;
	}
	
	body.buyAgainPlugin .caixa-dados #buyAgainDiv.unavailable button{
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
		width: 50%;
	}
	
}

@media screen and (max-width: 423px){
	body.buyAgainPlugin .caixa-dados #buyAgainDiv.loading{
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;	
	}
	
	body.buyAgainPlugin .caixa-dados #buyAgainDiv.loading .loader{
		right: auto;
		position: fixed;
		top: 40%;
	}
}
/* =============== SISTEMA DE ALERTA NO CARRINHO =============== */
.notification-cart {
	position: relative;
	padding: 15px;
	width: 100%;
	margin: 0;
}

.notification-cart .alert-msg {
	line-height: 20px;
	text-align: center;
	font-size: 15px;
}

.notification-cart .close-msg {
	position: absolute;
	top: 3px;
	right: 3px;
	cursor: pointer;
}


.popup-overlay {
	display: none;
    background-color: #212121aa;
    color: #0006;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    z-index: 1000;
}

.popup-overlay.active {
	display: block;
}

.popup-cart {
	position: relative;
	display: none;
	background: #fff;
    z-index: 1100;
    position: absolute;
    top: 30%;
    left: calc(50% - 20vw);
    width: 40vw;
    height: auto;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.popup-cart.active {
	display: block;
}

.popup-cart .box-popup {
	padding: 20px;
}

.popup-cart .box-popup header h3 {
	margin: 0;
	padding: 0;
	font-size: 22px;
}

.popup-cart .box-popup article p {
	font-size: 14px;
}

.popup-cart .accept-popup * {
	margin: 0;
}

.popup-cart .accept-popup {
	margin: 20px 0 10px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.popup-cart .accept-popup label {
	font-size: 14px;
	color: #333;
}

.popup-cart .close-msg {
	cursor: pointer;
	position: absolute;
	right: 5px;
	top: 5px;
}
.popup-cart .close-msg svg {
	width: 25px;
	height: 25px;
}


@media (max-width: 992px) {
	.popup-cart {
		width: 60vw;
		left: calc(50% - 30vw);
	}
}

.popup-wrap {
  display:none;
  position:fixed;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  text-align:center;
  padding:1rem;
  z-index: 1200;
  height: 100vh;
  background: transparent;
  
}

.popup-wrap .popup {
	    background: #fff;
    z-index: 1100;
    position: absolute;
    top: 30%;
    left: calc(50% - 20vw);
    width: 40vw;
    height: auto;
    border-radius: 5px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    padding: 20px;
}

.popup-wrap .close-msg {
	position: absolute;
    top: 0;
    right: 0;
}

.check-pu {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
	
}

.check-pu:hover {
	text-decoration: none;
}
 
.check-pu input,
.check-pu label {
	margin: 0;
	padding: 0;
}

.wrap-accept {
	margin: 10px 0 0 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.accept-submit {
	display: block;
	width: 120px;
	height: 40px;
	background: #4DC247;
	line-height: 40px;
	transition: 0.3s ease-in-out;
	border-radius: 3px;
	opacity: 0.5;
	cursor: default;
	position: relative;
}

.accept-submit.op {
	opacity: 1;
	cursor: pointer;
}

.accept-submit:hover {
	text-decoration: none;
	opacity: .7;
}

.accept-submit span {
	color: #fff;
}


/* =============== SISTEMA DE RASPADINHA =============== */
.d-none {
  display: none !important;
}

.scratch-ticket {
  position: fixed;
  z-index: 999;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  left: -115px;
  border-radius: 0px 8px 8px 0px;
  -webkit-clip-path: polygon(100% 0, 91% 50%, 100% 100%, 0% 100%, 0 55%, 0% 0%);
  clip-path: polygon(100% 0, 91% 50%, 100% 100%, 0% 100%, 0 55%, 0% 0%);
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.scratch-ticket.active {
  left: 0;
}

.scratch-ticket .container-modal {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}


.scratch-ticket .game-section {
  text-align: center;
  padding: 5px 30px;
  text-shadow: 1px 2px rgba(0, 0, 0, .5);
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  border-right: 5px dotted #FFF;
  transition: .2s;
}


.scratch-ticket .game-section h4 {
  line-height: 15px;
  font-weight: 700;
}

.scratch-ticket .coupon-section {
  min-width: 55px;
  padding: 10px 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  cursor: pointer;
}

.scratch-ticket .coupon-section:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

.scratch-ticket .coupon-section i {
  font-size: 25px;
  text-shadow: 1px 2px rgba(0, 0, 0, .5);
}

.modal-coupon {
  position: fixed;
  z-index: 16777271;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-4000px);
  -ms-transform: translateY(-4000px);
  transform: translateY(-4000px);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.modal-coupon.active {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.modal-coupon .modal-coupon-content {
  position: relative;
  min-width: 500px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin: 10px;
  border-radius: 4px;
}

.modal-coupon .modal-coupon-content .content-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
}

.modal-coupon .modal-coupon-content .content-section i {
  font-size: 40px;
}

.modal-coupon .modal-coupon-content .content-section h2 {
  font-size: 38px;
  text-shadow: 1px 2px rgba(0, 0, 0, .5);
  text-transform: uppercase;
}


.modal-coupon .modal-coupon-content .content-section .content .modal-form {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1.5fr 25px 1fr;
  grid-template-columns: 1.5fr 1fr;
  gap: 25px;
  margin: 30px 0;
}

.modal-coupon .modal-coupon-content .content-section .content .modal-form input {
  height: 40px;
  width: auto;
  min-width: 270px;
  margin-bottom: 0px;
}

.modal-coupon .modal-coupon-content .content-section .content .modal-form #modal-submit {
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  /*background: #000;*/
  border-radius: 4px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}


.modal-coupon .modal-coupon-content .content-section .content p {
  font-size: 22px;
}

.modal-coupon .modal-coupon-content .content-section .content p span {
  font-weight: 700;
}

.modal-coupon .modal-coupon-content .content-section .content .modal-btn-game {
  font-size: 18px;
  font-weight: 600;
  padding: 15px 40px;
  /*background:#000;*/
  /*color:#fff;*/
  border-radius: 4px;
  margin-top: 25px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.modal-coupon .modal-coupon-content .content-section .content .modal-btn-game:hover {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.modal-coupon .modal-coupon-content .content-section .content .coupon {
  position: relative;
  min-width: 260px;
  min-height: 180px;
  padding: 12px;
  margin-top: 20px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
  background: #E8C005;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon.active {
  display: block;
  -webkit-animation: fadeIn .3s ease;
  animation: fadeIn .3s ease;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code {
  width: 100%;
  min-height: 180px;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  background: rgb(255, 255, 255);
  border: 4px solid #5B42F3;
  border-radius: 8px;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  min-height: 180px;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code-content i {
  font-size: 35px;
  color: #E8C005;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code-content .msg {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code-content .coupon-code {
  font-size: 16px;
  color: #444;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code-content .coupon-code span {
	color: #E8C005;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code .code-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
}

.modal-coupon .modal-coupon-content .content-section .content .coupon .code .code-overlay .dot-modal {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;
  z-index: 999;
  min-width: 50px;
  height: 50%;
  background: #c6c6c6;
}

.modal-coupon .modal-coupon-content .content-section .coupon .circle {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.modal-coupon .modal-coupon-content .content-section .coupon .circle:nth-child(1) {
  left: -15px;
}

.modal-coupon .modal-coupon-content .content-section .coupon .circle:nth-child(2) {
  right: -15px;
}

.modal-coupon .modal-coupon-content .close-coupon-btn {
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 22px;
  width: 40px;
  height: 40px;
  padding: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  cursor: pointer;
}


@media (max-width:767px) {
  .modal-coupon .modal-coupon-content {
    min-width: 280px;
    min-height: 280px;
  }

  .modal-coupon .modal-coupon-content .content-section h2 {
    font-size: 24px;
  }

  .modal-coupon .modal-coupon-content .content-section p {
    font-size: 16px;
  }

  .modal-coupon .modal-coupon-content .content-section .content .coupon {
    min-width: 250px;
  }
}

@media (max-width:420px) {
  .modal-coupon .modal-coupon-content .content-section .content .modal-form {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
/* =============== UPLOAD DE ARQUIVOS =============== */
body.productFile-plugin.pagina-produto .uploadFileDiv {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
  margin: 20px 0;
}

body.productFile-plugin.pagina-produto .uploadFileDiv form{
  margin: 0;
}

body.productFile-plugin.pagina-produto .uploadFileDiv #uploadFile span{
	display: block;
}


body.productFile-plugin.pagina-produto .uploadFileDiv #uploadFile label>span:last-child{
	font-size: 10px;
	font-weight: normal;
}
/* =============== SISTEMA DE EMBALAGEM PARA PRESENTE =============== */
body.package-plugin.hide-checkboxAll .checkboxAll {
  display: none;
}
body.package-plugin .tabela-carrinho th.package-theader {
  min-width: 145px;
  padding-left: 4px;
  padding-right: 4px;
}
body.package-plugin .tabela-carrinho .product-package .quantidade > a {
  display: none;
}
body.package-plugin .tabela-carrinho .product-package .excluir {
  display: none;
}
body.package-plugin .tabela-carrinho .product-package .atualizar-quantidade {
  display: none !important;
}
body.package-plugin .tabela-carrinho .product-package .checkboxProdCel > div {
  display: none;
}
body.package-plugin .tabela-carrinho .checkboxProdCel {
  text-align: center;
}
@media screen and (min-width: 768px) {
  body.package-plugin .tabela-carrinho .checkboxProdCel label[for=checkboxProd] {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  body.package-plugin .tabela-carrinho .checkboxProdCel > div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  body.package-plugin .tabela-carrinho .checkboxProdCel > div label[for=checkboxProd] {
    margin-right: 7px;
    margin-bottom: 0;
  }
  body.package-plugin .tabela-carrinho .checkboxProdCel > div input.checkboxProd {
    margin: 0;
  }
}
body.package-plugin .checkboxAll {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 12px;
}
/* =============== ROLETA =============== */
.d-none-roullete {
  display: none !important;
}

.roulette-ticket {
  position: fixed;
  z-index: 999;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 60%;
  left:-115px;
  border-radius: 0px 30px 30px 0px;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.roulette-ticket.active {
  left: 0;
}

.roulette-ticket .container-modal {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}


.roulette-ticket .game-section-roulette {
  text-align: center;
  padding: 5px 30px;
  color: #FFFF;
  text-shadow: 1px 2px rgba(0, 0, 0, .5);
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  border-right: 5px dotted #FFF;
  transition: .2s;
}


.roulette-ticket .game-section-roulette h4 {
  line-height: 15px;
  font-weight: 700;
}

.roulette-ticket .coupon-roulette-section {
  min-width: 55px;
  padding: 10px 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  cursor: pointer;
}

.roulette-ticket .coupon-roulette-section:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

.roulette-ticket .coupon-roulette-section i {
  font-size: 25px;
  text-shadow: 1px 2px rgba(0, 0, 0, .5);
}

/** Modal **/
.modal-coupon-roulette {
  position: fixed;
  z-index: 16777271;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-4000px);
  -ms-transform: translateY(-4000px);
  transform: translateY(-4000px);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.modal-coupon-roulette.active {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.modal-coupon-roulette .modal-coupon-roulette-content {
  position: relative;
  min-width: 500px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin: 10px;
  border-radius: 4px;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section h2 {
  font-size: 38px;
  color: #fff;
  text-shadow: 1px 2px rgba(0, 0, 0, .5);
  text-transform: uppercase;
}


.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .modal-form-roulette {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1.5fr 25px 1fr;
  grid-template-columns: 1.5fr 1fr;
  gap: 25px;
  margin: 20px 0;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .modal-form-roulette input {
  height: 40px;
  width: auto;
  min-width: 270px;
  margin-bottom: 0px;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .modal-form-roulette #modal-submit-roulette {
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content p {
  font-size: 22px;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content p span {
  font-weight: 700;
  color: #000;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .btn-game-roulette,
.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .btn-spin {
  font-size: 18px;
  font-weight: 600;
  padding: 15px 40px;
  border-radius: 4px;
  margin-top: 10px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .btn-game-roulette:hover,
.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .btn-spin:hover {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .btn-copy {
  font-size: 22px;
  font-weight: 600;
  padding: 15px 60px;
  border-radius: 4px;
  margin-top: 10px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  text-transform: uppercase;
  animation: fadeIn .3s linear;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .coupon-roulette-container {
  display: none;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .coupon-roulette-container.active {
  display: block;
  -webkit-animation: fadeIn .3s ease;
  animation: fadeIn .3s ease;
}

.modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .coupon-roulette-container .code-content i {
  font-size: 50px;
  color: #e8c005;
  margin: 15px;
}

.modal-coupon-roulette .modal-coupon-roulette-content .close-coupon-btn-roulette {
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 22px;
  width: 40px;
  height: 40px;
  padding: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  cursor: pointer;
}


@media (max-width:767px) {
  .modal-coupon-roulette .modal-coupon-roulette-content {
    min-width: 280px;
  }

  .modal-coupon-roulette .modal-coupon-roulette-content .content-section h2 {
    font-size: 24px;
  }

  .modal-coupon-roulette .modal-coupon-roulette-content .content-section p {
    font-size: 16px;
  }

  .modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .coupon-roulette-container {
    min-width: 250px;
  }
  
  #canvas {
  	transform: scale(0.8);
  }

}


@media (max-width:420px) {
  .modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .modal-form-roulette {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .modal-coupon-roulette .modal-coupon-roulette-content .content-section .content p {
    font-size: 18px;
  }
  
  #canvas {
  	transform: scale(0.7);
  }
  
  .modal-coupon-roulette .modal-coupon-roulette-content .content-section .content .coupon-roulette-container {
  	max-height:450px;
  }
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
/* =============== SISTEMA DE CUPOM FLUTUANTE =============== */
 #cupom-flutuante {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

    position: fixed;
    top: 60%;
    
    height: 0px;
	border-radius: 10px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in;
    z-index: 99999999999;
    
}

#cupom-flutuante.hidde {
	right: -100px;
}

#cupom-flutuante.active {
	right: 0px;
}

.btn-cupom {
	position: absolute;
    left: -35px;
	
	text-align: center;
	font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
	
	width: 100px;
    height: 100px;
    border-radius: 10px;
    z-index: 99999999998;
    cursor: pointer;
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
}

.btn-cupom p {
	margin: 5px 0 0 0;
}


.etiqueta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	
	width: 100px;
	height: 100px;
    border-radius: 10px;
    z-index: 99999999999;
}

.etiqueta .top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    
	height: 50%;
}

.etiqueta .bot {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    
	height: 50%;
}

.titulo {
	font-size: 14px;
    font-weight: 800;
}

.porcentagem {
	font-size: 20px;
    font-weight: 800;
}

.cupom {
	text-align: center;
    font-size: 13px;
    font-weight: 600;
}

.nome-cupom {
	font-size: 13px;
    font-weight: 800;
    cursor: pointer;
    
    color: rgb(255, 255, 0);
}
/* =============== SISTEMA DE ETIQUETA PERSONALIZADA =============== */
.pagina-produto .conteiner-imagem>div{
  position: relative;
  overflow: hidden;
}

.pagina-produto .conteiner-imagem>div {
  @media(max-width: 767px){
    position: relative !important;
  }

  .etiqueta-filho {
    left: -40px;
    top: 19px;
  
    @media(max-width: 767px){
      left: -12px !important;
      top: 4px !important;
    }  
  }
}


.etiqueta-filho {
	position: absolute;
    top: 0;
    left: 0;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    line-height: 1;
    white-space: normal;
    border: none;
    padding: 9px 9px 7px;
    
    -webkit-transform: translate(-32%, 88%) rotateZ(-45deg);
    
        -ms-transform: translate(-32%, 88%) rotate(-45deg);
    
            transform: translate(-32%, 88%) rotateZ(-45deg);
    width: 100%;
    text-align: center;
    background: black;
    color: white;
    
    
}

.product {
	overflow: hidden;
}
/* =============== COMPRE POR CATEGORIA =============== */
.comprar-por-categoria a{
	display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 120px;
    max-width: 120px;
}

.comprar-por-categoria a label{
    font-size: 16px;
    font-weight: 500;
    margin: 11px 0 0 0;
    text-align: center;
}

.comprar-por-categoria a img{
	box-sizing: border-box;
    margin-right: 0;
    border: 1px solid #eee;
    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 11px;
   
    object-fit: cover;
}

.comprar-por-categoria a[data-forma="redondo"] img{
	 border-radius: 300px;
}

.comprar-por-categoria .slick-arrow,
.comprar-por-categoria .slick-arrow svg{
	width: 20px;
    height: 20px;
}

.slick-arrow svg{
  width: 20px !important;
  height: 20px !important;
}

/* =============== PLUGIN CALCULADORA DE M2 =============== */
.preco-caixa {
  background-color: #e08d30;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 15px;
  color: #fff;
}
@media(max-width: 767px){
.preco-caixa{
  width: 100% !important;
    font-size: 15px;
}

.si-calculadora .mb-4 > div {
    grid-template-columns: 59px 40px !important; 
}

.si-calculadora .mb-4 > div > input{
  padding: 10px;
}
}

.preco-caixa strong {
  margin-right: 5px;
}

.preco-caixa span {
  margin-left: 5px;
}

.si-calculadora{
box-shadow: 0 0 9px -2px #ccc;
  border-radius: 8px;
  padding: 10px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: left;
}

.si-calculadora h4{
border-bottom: 1px solid black;
padding: 5px 0px 10px;
margin: 0;
margin-bottom: 10px;
}

.si-calculadora .hide{
display: none;
}

.si-calculadora .mb-4 > div{
display: grid !important;
  grid-template-columns: 100px 45px;
}

.si-calculadora input{
width: 100%;
max-width: 100%;
  padding: 4px 6px;
  padding-right: 0;
  min-height: 30px;
  border-radius: 0px !important;
  margin-bottom: 0;
  border: 1px solid #ccc !important;
  border-right-width: 0 !important;
  color: #000;
}

.si-calculadora input::placeholder{
color: #000;
}

.si-calculadora .mb-4 > div > div,
.si-calculadora .mb-4 > div > input{
height: 35px;
}

.si-calculadora .mb-4 > div > div{
flex: 1 1 0%;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(204, 204, 204);
  font-size: 17px;
  user-select: none;
}

.si-calculadora .calculadora label{
display: block;
  color: rgb(0, 0, 0);
  font-size: 11px;
  cursor: default;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
}

.si-calculadora .calculadora button{
background-color: #e08d30;
  border-radius: 5px;
  transition: opacity 0.25s ease;
  opacity: 1;
  margin-top: 0;
  padding: 10px 20px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  appearance: none;
  display: block;
  position: relative;
  width: 120px;
  font-weight: 600;
}

.si-calculadora .calculadora > div{
display: flex;
flex-wrap: nowrap;
  margin: 0 0 20px;
  justify-content: space-between;
  align-items: flex-end;
}

.si-calculadora .trocar{
font-weight: bold;
font-size: 14px;
cursor: pointer;
}

.si-calculadora .resultado{
display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  font-size: 14px;
}

.listagem-item .preco-produto > :nth-child(1) strong{
font-size: 17px !important;
}

.si-calculadora input{
margin: 0 !important;
}
/* =============== GRADE ABERTA =============== */
.v-list .item > span{
  min-width: 35px !important;
}
.variations-content{
  margin-bottom: 20px;
}
.v-tarja-msg{
  line-height: 1;
}

.acoes-produto.v-acoes{
  display: flex;
    gap: 10px;
    align-items: center;
}

.acoes-produto.v-acoes  .preco-produto{
  margin-bottom: 0 !important;
}
/* =============== LOADING =============== */
.background-masker {
  background-color: #fff;
  position: absolute;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}


.product.indisponivel:after {
  background-color: #f2f2f2;
  bottom: 0;
  color: #000;
  content: "INDISPONÍVEL";
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
}


.product.indisponivel .buy-wrapper.product-button,
.product.indisponivel .product-button{
  display: none !important;
}

.pagina-produto .cep > ul{
	padding: 10px;
    border-width: 1px;
    border-style: solid;
    margin: 15px 0 0 0;
}
.pagina-produto .cep > ul li{
    font-size: 14px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DDD;
}
.pagina-produto .cep ul li .valor {
    margin-right: 5%;
    min-width: 60px;
}
.pagina-produto .cep ul li span {
    font-size: 13px;
}
.pagina-produto .cep ul li>* {
    display: inline-block;
}
.pagina-produto .cep ul li .prazo {
    margin-right: 5%;
    font-weight: bold;
    min-width: 80px;
}
.pagina-produto .cep .aviso-disponibilidade {
    font-size: 11px;
    margin-top: 5px;
}
.pagina-produto .cep ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.product-parcel.show{
  display: block !important;
  font-size: 14px;
}